<script lang="ts">
  import { trackEvent } from '@wizbii/utils/tracking';
  import { onMount } from 'svelte';
  import CrossIcon from './icons/cross.svg';
  import FinalizeNps from './lib/FinalizeNps.svelte';
  import SelectScore from './lib/SelectScore.svelte';
  import UserFeedback from './lib/UserFeedback.svelte';
  import { CLOSE_NPS_EVENT_NAME, QUESTION_IDS } from './main';
  import type { NpsSurveyWebservice, Survey } from './webservices/nps-survey.webservice';

  export let productName: string;
  export let survey: Survey;
  export let userId: string;
  export let surveyId: string;
  export let logoUrl: string;
  export let bgColor: string;
  export let textColor: string;
  export let buttonFgColor: string;
  export let buttonBgColor: string;
  export let buttonRadius: string;
  export let textSecondaryColor: string;
  export let sendToSupport = false;
  export let hasUserSubmittedNote = false;
  export let npsSurveyWebservice: NpsSurveyWebservice;

  $: feedbackIsValidated = false;
  $: isVisible = false;
  $: disabled = false;
  $: score = undefined as number | undefined;
  $: hasRated = false;
  $: maxHeight = 0;
  $: {
    if (!hasRated && isVisible) {
      maxHeight = 300;
    } else if (!feedbackIsValidated && isVisible) {
      maxHeight = 500;
    } else if (feedbackIsValidated && hasRated && isVisible) {
      maxHeight = 700;
    } else if (!isVisible) {
      maxHeight = 0;
    }
  }

  onMount(() => {
    openWidget();
  });

  const openWidget = () => {
    setTimeout(() => {
      trackEvent('nps', 'display');
      isVisible = true;
    });
  };

  const closeWidget = () => {
    trackEvent('nps', 'click', 'fermer');
    dispatchEvent(new Event('close-nps-widget'));
    isVisible = false;
  };

  const scoreSelected = async ({ detail }: CustomEvent<{ score: number }>) => {
    disabled = true;
    await npsSurveyWebservice
      .answerNpsSurvey({
        surveyId: surveyId,
        userId,
        answers: [
          {
            questionId: QUESTION_IDS[0],
            value: detail.score.toString(),
          },
        ],
        platform: productName,
        completed: true,
        sendToSupport,
      })
      .then(() => {
        score = detail.score;
        hasRated = true;
      })
      .catch((err) => {
        throw new Error(`An error occured sending NPSSurvey score: ${err}`);
      })
      .finally(() => {
        disabled = false;
      });
  };

  const feedbackValidated = async ({ detail }: CustomEvent<{ feedback: string }>) => {
    disabled = true;
    if (!detail.feedback.length) {
      feedbackIsValidated = true;
      return;
    }
    await npsSurveyWebservice
      .answerNpsSurvey({
        surveyId: surveyId,
        userId,
        answers: [
          {
            questionId: QUESTION_IDS[1],
            value: detail.feedback,
          },
        ],
        platform: productName,
        completed: true,
        sendToSupport,
      })
      .then(() => {
        feedbackIsValidated = true;
      })
      .catch((err) => {
        throw new Error(`An error occured sending NPSSurvey feedback: ${err}`);
      })
      .finally(() => {
        disabled = false;
      });
  };

  addEventListener(CLOSE_NPS_EVENT_NAME, closeWidget);
</script>

<div
  class="nps-widget"
  style={`--nps-max-height: ${maxHeight}px;--nps-bg-color: ${bgColor};--nps-text-color: ${textColor};--nps-button-fg-color: ${buttonFgColor};--nps-button-radius: ${buttonRadius};--nps-button-bg-color: ${buttonBgColor};--nps-text-secondary-color: ${textSecondaryColor};`}
  class:nps-widget--open={isVisible}
  class:nps-widget--hidden={maxHeight === 0}
>
  <button on:click={closeWidget} class="nps-widget__icon" type="button"
    ><CrossIcon /></button
  >
  {#if !hasRated}
    <SelectScore
      {disabled}
      {survey}
      {productName}
      on:scoreSelected={async (event) => {
        await scoreSelected(event);
      }}
    />
  {:else if !feedbackIsValidated}
    <UserFeedback
      {surveyId}
      {npsSurveyWebservice}
      {userId}
      {sendToSupport}
      {disabled}
      {survey}
      {score}
      {productName}
      {hasUserSubmittedNote}
      on:feedbackValidated={async (event) => {
        await feedbackValidated(event);
      }}
    />
  {:else}
    <FinalizeNps {logoUrl} {productName} clickButton={closeWidget} />
  {/if}
</div>

<style lang="scss">
  .nps-widget {
    --nps-user-feedback-icon-font-size: 3.125rem;
    --nps-user-feedback-content-font-size: 1.125rem;
    --nps-user-feedback-content-max-width: none;
    --nps-user-feedback-textarea-min-height: 5rem;
    --nps-user-feedback-textarea-margin: 1.25rem;
    --nps-finalize-content-margin: 1.25rem auto 0.5rem;
    --nps-finalize-content-margin-bottom: 1.25rem;
    --nps-finalize-content-font-size: 1.125rem;
    --nps-finalize-content-font-weight: 500;
    --nps-finalize-content-description-font-size: 0.875rem;
    --nps-user-feedback-container: unset;

    z-index: 999999;
    background-color: var(--nps-bg-color, white);
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    width: 100vw;
    padding: 1.25rem 1rem;
    opacity: 0;
    flex-direction: column;
    align-items: center;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 -0.125rem 0.375rem -0.0625rem rgb(0 0 0 / 10%);
    max-height: var(--nps-max-height);
    transition:
      max-height 300ms ease-in,
      opacity 300ms ease-in-out;

    &--open {
      opacity: 1;
    }

    &--hidden {
      padding: 0;
      display: none;
    }

    &__icon {
      cursor: pointer;
      border: none;
      background-color: transparent;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
    }
  }
</style>
