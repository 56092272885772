<script lang="ts">
  import FinalizeNps from './lib/FinalizeNps.svelte';
  import UserFeedback from './lib/UserFeedback.svelte';
  import { QUESTION_IDS } from './main';
  import { t } from './translations/i18n';
  import { TradEnum } from './translations/trad.enum';
  import {
    type NpsSurveyWebservice,
    type Survey,
  } from './webservices/nps-survey.webservice';

  export let productName: string;
  export let npsSurveyWebservice: NpsSurveyWebservice;
  export let userId: string;
  export let surveyId: string;
  export let finalizeRedirectUrl: string;
  export let trustpilotRedirectUrl: string;
  export let survey: Survey;
  export let logoUrl: string;
  export let bgColor: string;
  export let textColor: string;
  export let buttonFgColor: string;
  export let buttonBgColor: string;
  export let buttonRadius: string;
  export let textSecondaryColor: string;
  export let sendToSupport = false;
  export let score: number;
  export let displayValidationPage: boolean;
  export let hasUserSubmittedNote: boolean;
  export let hasUserCommented: boolean;

  $: displayFinalzePage = displayValidationPage;

  const finalizeRedirect = () => {
    location.href = finalizeRedirectUrl;
  };

  const feedbackValidated = async ({
    detail,
  }: CustomEvent<{ feedback: string; trustpilot?: boolean }>) => {
    await npsSurveyWebservice
      .answerNpsSurvey({
        surveyId: surveyId,
        userId,
        answers: [
          {
            questionId: QUESTION_IDS[0],
            value: score.toString(),
          },
          {
            questionId: QUESTION_IDS[1],
            value: `${detail.feedback}`,
          },
        ],
        platform: productName,
        completed: true,
        sendToSupport,
      })
      .then(() =>
        detail.trustpilot ? (displayFinalzePage = false) : (displayFinalzePage = true)
      )
      .catch((err) => {
        throw new Error(`An error occured sending NPSSurvey feedback: ${err}`);
      });
  };
</script>

<div
  class="nps-landing"
  style={`--nps-bg-color: ${bgColor};--nps-text-color: ${textColor};--nps-button-fg-color: ${buttonFgColor};--nps-button-bg-color: ${buttonBgColor};--nps-button-radius: ${buttonRadius};--nps-text-secondary-color: ${textSecondaryColor};`}
>
  {#if !displayFinalzePage && !hasUserCommented}
    <UserFeedback
      {surveyId}
      {npsSurveyWebservice}
      {userId}
      {sendToSupport}
      {score}
      {survey}
      {productName}
      {trustpilotRedirectUrl}
      {hasUserSubmittedNote}
      on:feedbackValidated={async (event) => {
        await feedbackValidated(event);
      }}
    />
  {:else}
    <FinalizeNps
      {logoUrl}
      {productName}
      buttonLabel={$t(TradEnum.continue)}
      clickButton={finalizeRedirect}
    />
  {/if}
</div>

<style lang="scss">
  .nps-landing {
    --nps-user-feedback-icon-font-size: 3.125rem;
    --nps-user-feedback-content-font-size: 2rem;
    --nps-user-feedback-content-max-width: 37.5rem;
    --nps-user-feedback-textarea-min-height: 15.125rem;
    --nps-user-feedback-textarea-margin: 4.375rem 1.875rem;
    --nps-finalize-content-margin: 2.5rem auto 0.5rem;
    --nps-finalize-content-margin-bottom: 2.5rem;
    --nps-finalize-content-font-size: 2rem;
    --nps-finalize-content-font-weight: 700;
    --nps-finalize-content-description-font-size: 1.25rem;
    --nps-user-feedback-container: unset;
  }
</style>
