<script lang="ts">
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import { TradEnum } from '../translations/trad.enum';
  import { t } from '../translations/i18n';

  export let logoUrl = '';
  export let productName = '';
  export let buttonLabel = $t(TradEnum.close);
  export let clickButton: () => void;

  $: isVisible = false;

  onMount(async () => {
    isVisible = true;
  });
</script>

{#if isVisible}
  <div transition:fade class="container">
    <div class="container__content">
      <img class="container__content__image" src={logoUrl} alt="logo" />
      <p class="container__content__title">{$t(TradEnum.tanksAnswer)}</p>
      <p class="container__content__description">
        {$t(TradEnum.itHelpsUs, { productName })}
      </p>
    </div>
    <button type="button" class="container__button" on:click={clickButton}
      >{buttonLabel}</button
    >
  </div>
{/if}

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.5rem;
    height: var(--nps-user-feedback-container, unset);

    &__content {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      margin-bottom: 1.25rem;
      &__image {
        width: var(--nps-user-feedback-icon-font-size, 3.125rem);
        margin: auto;
      }

      &__title {
        color: var(--nps-text-color, #222333);
        font-size: 1.3125rem;
        font-weight: 500;
        line-height: 1.4;
        text-align: center;
        margin: 1.5rem auto 0.5rem;
      }
      &__description {
        color: var(--nps-text-secondary-color, #5d5871);
        font-size: 0.8125rem;
        line-height: 1.5;
        text-align: center;
        margin: 0;
      }
    }
    &__button {
      border: none;
      color: var(--nps-button-fg-color, white);
      background-color: var(--nps-button-bg-color, #5c59f2);
      border-radius: var(--nps-button-radius, 4px);
      padding: 0.625rem 1.5rem;
      font-size: 0.8125rem;
      font-weight: 500;
      line-height: 1.4;
      width: 100%;
      margin-top: auto;

      &:hover {
        cursor: pointer;
      }
    }

    @media screen and (min-width: 46rem) {
      margin-top: -1.875rem;
      height: unset;

      &__content {
        border: none;
        padding: 0;
        margin-bottom: var(--nps-finalize-content-margin-bottom, 1.25rem);
        background-color: unset;
        &__image {
          width: 4.375rem;
        }
        &__title {
          margin: var(--nps-finalize-content-margin, 1.25rem auto 0.5rem);
          font-size: var(--nps-finalize-content-font-size, 1.125rem);
          font-weight: var(--nps-finalize-content-font-weight, 500);
        }

        &__description {
          font-size: var(--nps-finalize-content-description-font-size, 0.875rem);
        }
      }

      &__button {
        width: unset;
      }
    }
  }
</style>
