import type { NPSResponse, NpsSurveyAnswer } from '../models/nps.interface';
import { getJwt, setCookie } from '../services/jwt.service';
import { AuthWebservice } from './auth.webservice';
import { AlreadyAnsweredNPSException } from './AlreadyNPSAnswerdException';

export interface Survey {
  id: string;
  name: string;
  surveyTemplateId: string;
  steps: {
    name: string;
    questions: {
      label: string;
      minLabel?: string;
      maxLabel?: string;
      min?: string;
      max?: string;
    }[];
  }[];
}

export class NpsSurveyWebservice {
  readonly #wizbiiEnvFqdn: string;
  readonly #appId: string;
  readonly #baseUrl: string;

  constructor(wizbiiEnvFqdn: string, appId: string) {
    this.#wizbiiEnvFqdn = wizbiiEnvFqdn;
    this.#baseUrl = `https://survey-api.${this.#wizbiiEnvFqdn}/v2`;
    this.#appId = appId;
  }

  readonly answerNpsSurvey = async (npsSurveyAnswer: NpsSurveyAnswer): Promise<unknown> =>
    fetch(`${this.#baseUrl}/answer`, {
      method: 'POST',
      body: JSON.stringify(npsSurveyAnswer),
    })
      .then((response) => response.json())
      .catch((err) => {
        throw new Error('[WIZBII NPS] An error occurred while answering survey: ', err);
      });

  readonly getSurvey = async (surveyId: string): Promise<Survey> =>
    fetch(`${this.#baseUrl}/survey/${surveyId}`)
      .then((response) => response.json())
      .catch((err) => {
        throw new Error('[WIZBII NPS] An error occurred while getting survey: ', err);
      });

  readonly getUserAnswerBySurvey = async (
    userId: string,
    surveyId: string,
    jwtCookieName: string
  ): Promise<unknown> => {
    const initialResponse = await fetch(
      `${this.#baseUrl}/answer/${userId}/survey/${surveyId}`,
      {
        headers: { Authorization: `Bearer ${getJwt(jwtCookieName)?.token}` },
      }
    ).catch((err) => {
      throw new Error('[WIZBII NPS] An error occurred while getting user answer: ', err);
    });
    if (initialResponse?.ok) {
      throw new AlreadyAnsweredNPSException(
        `[WIZBII NPS] ${userId} already answered survey ${surveyId}`
      );
    }
    if (initialResponse?.status === 404) {
      // no answer for this user, nps can be displayed
      return;
    }
    if (initialResponse?.status === 401) {
      const authWebservice = new AuthWebservice(this.#wizbiiEnvFqdn, this.#appId);
      const response = await authWebservice.refreshToken(jwtCookieName);
      if (response?.ok) {
        return response
          .json()
          .then((data) => setCookie(jwtCookieName, data))
          .then(() => this.getUserAnswerBySurvey(userId, surveyId, jwtCookieName));
      }
    }
  };

  readonly getUserData = async (
    userId: string,
    surveyId: string,
    jwtCookieName: string
  ): Promise<NPSResponse> => {
    return (
      await fetch(`${this.#baseUrl}/answer/${userId}/survey/${surveyId}`, {
        headers: { Authorization: `Bearer ${getJwt(jwtCookieName)?.token}` },
      })
    ).json();
  };
}
