export enum TradEnum {
  thanksForScore,
  yourMessage,
  tanksAnswer,
  itHelpsUs,
  continue,
  leaveReview,
  thanksGoodScore,
  stayWithUs,
  tellUsWhatYouLike,
  thanksForScoreBis,
  enterAnswer,
  validate,
  close,
}
