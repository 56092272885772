import Cookies from 'js-cookie';

export const getJwt = (
  jwtCookieName: string
): { token?: string; refreshToken?: string } => {
  const rawCookie = Cookies.get(jwtCookieName);
  return rawCookie ? JSON.parse(rawCookie) : null;
};

export const setCookie = (key: string, value: string): string | undefined =>
  Cookies.set(key, value);
