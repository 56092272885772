<script lang="ts">
  import { trackEvent } from '@wizbii/utils/tracking';
  import { createEventDispatcher, onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import { NpsSurveyWebservice, type Survey } from '../webservices/nps-survey.webservice';
  import { QUESTION_IDS } from '../main';

  export let score = 0;
  export let productName: string;
  export let disabled = false;
  export let survey: Survey;
  export let trustpilotRedirectUrl = '';
  export let npsSurveyWebservice: NpsSurveyWebservice;
  export let surveyId: string;
  export let userId: string;
  export let sendToSupport: boolean;
  export let hasUserSubmittedNote: boolean;

  enum NpsSatisfactionEnum {
    UNSATISFIED = 'unsatisfied',
    DETRACTOR = 'detractor',
    PROMOTOR = 'promotor',
  }

  const dispatch = createEventDispatcher<{
    feedbackValidated: { feedback: string; trustpilot?: boolean };
  }>();

  $: value = '';
  $: isVisible = false;

  onMount(() => {
    isVisible = true;

    if (!hasUserSubmittedNote) {
      npsSurveyWebservice
        .answerNpsSurvey({
          surveyId,
          userId,
          answers: [
            {
              questionId: QUESTION_IDS[0],
              value: score.toString(),
            },
          ],
          platform: productName,
          completed: true,
          sendToSupport: sendToSupport,
        })
        .catch((err) => {
          throw new Error(`An error occurred while sending NPSSurvey score: ${err}`);
        });
    }
  });

  const feedbackValidated = (feedback: string) => {
    trackEvent('nps', 'click', 'validation');
    dispatch('feedbackValidated', { feedback });
  };

  const redirectTrustpilot = () => {
    trackEvent('nps', 'click ', 'redirection avis trustpilot');
    dispatch('feedbackValidated', { feedback: '', trustpilot: true });
  };
</script>

{#if isVisible}
  <div class="container" transition:fade>
    {#if trustpilotRedirectUrl?.length && score >= 9}
      <img
        class="container__icon"
        loading="lazy"
        alt=""
        src="https://storage.googleapis.com/wizbii-images/money/NPS/smiling-face-with-heart-eyes.png"
      />
      <p class="container__content">
        Merci pour votre super note&nbsp;! <br /> Restez sur votre lancée et déclarez-nous
        votre flamme sur Trustpilot&nbsp;!
      </p>
      <a
        class="container__button"
        target="_blank"
        on:click={() => redirectTrustpilot()}
        href={trustpilotRedirectUrl}>Laisser un avis</a
      >
    {:else}
      {#if score < 7}
        <img
          class="container__icon"
          loading="lazy"
          alt=""
          src="https://storage.googleapis.com/wizbii-images/money/NPS/wounded-head.png"
        />
        <p class="container__content">
          <!-- second question wording -->
          {(
            JSON.parse(survey.steps[1].questions[0].label)?.[
              NpsSatisfactionEnum.UNSATISFIED
            ] ??
            'Merci pour votre note, que devons-nous améliorer pour que vous soyez satisfait ?'
          ).replaceAll('{productName}', productName)}
        </p>
      {:else if score < 9}
        <img
          class="container__icon"
          loading="lazy"
          alt=""
          src="https://storage.googleapis.com/wizbii-images/money/NPS/happy-winking-face.png"
        />
        <p class="container__content">
          {(
            JSON.parse(survey.steps[1].questions[0].label)?.[
              NpsSatisfactionEnum.DETRACTOR
            ] ??
            'Merci pour votre note, que pouvons-nous améliorer pour que vous soyez encore plus satisfait ?'
          ).replaceAll('{productName}', productName)}
        </p>
      {:else}
        <img
          class="container__icon"
          loading="lazy"
          alt=""
          src="https://storage.googleapis.com/wizbii-images/money/NPS/smiling-face-with-heart-eyes.png"
        />
        <p class="container__content">
          {(
            JSON.parse(survey.steps[1].questions[0].label)?.[
              NpsSatisfactionEnum.PROMOTOR
            ] ??
            'Merci pour votre super note ! Dites nous ce que vous aimez le plus avec {productName}'
          ).replaceAll('{productName}', productName)}
        </p>
      {/if}
      <div class="container__feedback">
        <label class="container__feedback-label" for="feedback">Votre message</label>
        <textarea
          id="feedback"
          class="container__textarea"
          placeholder="Saisissez ici votre réponse"
          bind:value
        />
      </div>
      <button
        type="button"
        class="container__button"
        {disabled}
        on:click={() => feedbackValidated(value)}>Valider</button
      >
    {/if}
  </div>
{/if}

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: var(--nps-user-feedback-container, unset);

    &__icon {
      width: var(--nps-user-feedback-icon-font-size, 3.125rem);
      margin-bottom: 1.5rem;
    }

    &__content {
      text-align: center;
      margin: 0 0 2rem;
      color: var(--nps-text-color, #222333);
      font-size: 1.25rem;
      font-weight: bold;
      max-width: var(--nps-user-feedback-content-max-width, none);
    }

    &__feedback {
      width: 100%;
      margin-top: auto;
    }

    &__feedback-label {
      display: flex;
      margin: 0 auto 0.75rem 0;
      font-size: 0.875rem;
      font-weight: 500;
    }

    &__textarea {
      background-color: white;
      margin: 0 0 1.5rem;
      border-radius: 16px;
      border: 1px solid #a2bbda;
      width: 100%;
      max-width: 65.4375rem;
      min-height: 8.25rem;
      padding: 0.75rem;
      box-sizing: border-box;

      &::placeholder {
        color: #a1a1aa;
        font-size: 0.75rem;
        line-height: 1.5;
      }

      &:focus {
        outline-color: var(--nps-button-bg-color, #5c59f2);
      }
    }

    &__button {
      border-radius: var(--nps-button-radius, 4px);
      border: none;
      color: var(--nps-button-fg-color, white);
      background-color: var(--nps-button-bg-color, #5c59f2);
      padding: 0.625rem 1.5rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.4;
      text-decoration: none;
      cursor: pointer;
      width: 100%;
      &:hover {
        cursor: pointer;
      }
      &:disabled {
        filter: brightness(150%);
        cursor: initial;
      }
    }

    @media screen and (min-width: 46rem) {
      margin: -1.875rem auto 0;
      max-width: 65.5rem;

      &__icon {
        width: calc(var(--nps-user-feedback-icon-font-size, 3.125rem) * 1.4);
      }

      &__content {
        font-size: var(--nps-user-feedback-content-font-size, 1.125rem);
      }

      &__feedback {
        width: 100%;
      }

      &__feedback-label {
        font-size: 1rem;
      }

      &__textarea {
        min-height: var(--nps-user-feedback-textarea-min-height, 5rem);
      }

      &__button {
        width: unset;
      }
    }
  }
</style>
