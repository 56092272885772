import { TradEnum } from './trad.enum';
import { LocaleEnum } from '@wizbii/utils/models';

export type possibleLocaleEnums = LocaleEnum.fr_FR | LocaleEnum.it_IT;

export default {
  [LocaleEnum.fr_FR]: {
    [TradEnum.thanksForScore]:
      'Merci pour votre note, que devons-nous améliorer pour que vous soyez satisfait(e) ?',
    [TradEnum.yourMessage]: 'Votre message',
    [TradEnum.tanksAnswer]: 'Merci pour votre réponse',
    [TradEnum.itHelpsUs]: 'Vos retours nous aident à améliorer {productName}',
    [TradEnum.continue]: 'Continuer',
    [TradEnum.leaveReview]: 'Laisser un avis',
    [TradEnum.thanksForScoreBis]:
      'Merci pour votre note, que pouvons-nous améliorer pour que vous soyez encore plus satisfait ?',
    [TradEnum.thanksGoodScore]: 'Merci pour votre super note !',
    [TradEnum.stayWithUs]:
      'Restez sur votre lancée et déclarez-nous votre flamme sur Trustpilot',
    [TradEnum.tellUsWhatYouLike]:
      'Dites nous ce que vous aimez le plus avec {productName}',
    [TradEnum.enterAnswer]: 'Saisissez ici votre réponse',
    [TradEnum.validate]: 'Valider',
    [TradEnum.close]: 'Fermer',
  },
  [LocaleEnum.it_IT]: {
    [TradEnum.yourMessage]: 'Il tuo messaggio',
    [TradEnum.tanksAnswer]: 'Grazie per la tua risposta',
    [TradEnum.itHelpsUs]: 'Il tuo feedback ci aiuta a migliorare {productName}',
    [TradEnum.continue]: 'Continua',
    [TradEnum.leaveReview]: 'Lascia un commento',
    [TradEnum.thanksForScore]:
      'Grazie per la tua valutazione, potresti dirci cosa possiamo migliorare?',
    [TradEnum.thanksForScoreBis]:
      'Grazie per la tua valutazione, potresti dirci cosa possiamo migliorare?',
    [TradEnum.thanksGoodScore]: 'Grazie per la tua fantastica valutazione!',
    [TradEnum.stayWithUs]: 'Potresti condividere la tua esperienza anche su Trustpilot?',
    [TradEnum.tellUsWhatYouLike]: 'Dicci cosa ti è piaciuto di più di {productName}',
    [TradEnum.enterAnswer]: 'Inserisci qui la tua risposta',
    [TradEnum.validate]: 'Conferma',
    [TradEnum.close]: 'chiudere',
  },
} satisfies Record<possibleLocaleEnums, Record<TradEnum, string>>;
