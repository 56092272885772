import { derived } from 'svelte/store';
import translations, { type possibleLocaleEnums } from './translations';
import type { TradEnum } from './trad.enum';
import { locale } from '../stores/locale.store';

function translate(
  locale: possibleLocaleEnums,
  key: TradEnum,
  vars: { [key: string]: string }
) {
  if (!key) throw new Error('no key provided to $t()');
  if (!locale) throw new Error(`no translation for key "${key}"`);

  let text = translations[locale][key];

  if (!text) throw new Error(`no translation found for ${locale}.${key}`);

  Object.keys(vars).map((k) => {
    const regex = new RegExp(`{${k}}`, 'g');
    text = text.replace(regex, vars[k]);
  });

  return text;
}

export const t = derived(
  locale,
  ($locale) =>
    (key: TradEnum, vars = {}) =>
      translate($locale, key, vars)
);
