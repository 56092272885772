import NpsLanding from './NpsLanding.svelte';
import NpsWidget from './NpsWidget.svelte';
import { NpsSurveyWebservice } from './webservices/nps-survey.webservice';

import type { InitNpsConfig, InitNpsLandingConfig } from './models/config.interface';
import { AlreadyAnsweredNPSException } from './webservices/AlreadyNPSAnswerdException';
import type { NPSResponse } from './models/nps.interface';

export const CLOSE_NPS_EVENT_NAME = 'close-nps';
export const QUESTION_IDS = ['note-nps', 'commentaire-nps'];

const initNpsWidget = async (config: InitNpsConfig) => {
  const npsSurveyWebservice = new NpsSurveyWebservice(config.wizbiiEnvFqdn, config.appId);
  let hasUserSubmittedNote = false;
  await checkUserAnswer(
    config.userId,
    config.surveyId,
    npsSurveyWebservice,
    config.jwtCookieName
  ).catch((err) => {
    if (err instanceof AlreadyAnsweredNPSException) {
      hasUserSubmittedNote = true;
    }
  });
  const survey = await npsSurveyWebservice.getSurvey(config.surveyId).catch(() => {
    throw new Error("[WIZBII NPS] surveyId doesn't exist");
  });
  return new NpsWidget({
    target: document.querySelector('body') ?? document,
    props: {
      productName: config.productName,
      survey,
      userId: config.userId,
      surveyId: config.surveyId,
      logoUrl: config.logoUrl,
      bgColor: config.bgColor,
      textColor: config.textColor,
      buttonFgColor: config.buttonFgColor,
      buttonBgColor: config.buttonBgColor,
      buttonRadius: config.buttonRadius,
      textSecondaryColor: config.textSecondaryColor,
      sendToSupport: config.sendToSupport,
      npsSurveyWebservice: npsSurveyWebservice,
      hasUserSubmittedNote,
    },
  });
};

const checkNpsLandingProps = async (
  config: InitNpsLandingConfig,
  npsSurveyWebservice: NpsSurveyWebservice
) => {
  if (
    config.score === null ||
    config.score === undefined ||
    config.score < 0 ||
    config.score > 10
  ) {
    throw new Error('[WIZBII NPS] Score must be between 0 and 10');
  }
  await npsSurveyWebservice.getSurvey(config.surveyId).catch(() => {
    throw new Error("[WIZBII NPS] surveyId doesn't exist");
  });
};

const initNpsLanding = async (config: InitNpsLandingConfig) => {
  const npsSurveyWebservice = new NpsSurveyWebservice(config.wizbiiEnvFqdn, config.appId);
  const survey = await npsSurveyWebservice.getSurvey(config.surveyId).catch(() => {
    throw new Error("[WIZBII NPS] surveyId doesn't exist");
  });
  await checkNpsLandingProps(config, npsSurveyWebservice);
  let userScore: number = config.score;
  let userComment: string | undefined = undefined;
  let hasUserSubmittedNote = false;
  let displayValidationPage = false;

  await npsSurveyWebservice
    .getUserData(config.userId, config.surveyId, config.jwtCookieName)
    .then((response: NPSResponse) => {
      // replace url score by real user score from previous answer
      userScore = response.data['note-nps'];
      userComment = response.data['commentaire-nps'];
      hasUserSubmittedNote = true;
      // display validation page if user already answered
      // if trustpilot enabled, always display trustpilot
      !!config.trustpilotRedirectUrl.length && userScore >= 9
        ? (displayValidationPage = false)
        : (displayValidationPage = true);
    })
    .catch(
      // if error, do nothing
      () => {}
    );

  return new NpsLanding({
    target: document.querySelector(config.targetSelector) ?? document,
    props: {
      productName: config.productName,
      userId: config.userId,
      surveyId: config.surveyId,
      logoUrl: config.logoUrl,
      bgColor: config.bgColor,
      textColor: config.textColor,
      buttonFgColor: config.buttonFgColor,
      buttonBgColor: config.buttonBgColor,
      buttonRadius: config.buttonRadius,
      textSecondaryColor: config.textSecondaryColor,
      npsSurveyWebservice: npsSurveyWebservice,
      finalizeRedirectUrl: config.finalizeRedirectUrl,
      sendToSupport: config.sendToSupport,
      score: userScore,
      survey,
      trustpilotRedirectUrl: config.trustpilotRedirectUrl,
      displayValidationPage,
      hasUserSubmittedNote,
      hasUserCommented: !!userComment,
    },
  });
};

const closeNpsWidget = () => {
  dispatchEvent(new Event(CLOSE_NPS_EVENT_NAME));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).WizbiiNps = {
  widget: {
    init: initNpsWidget,
    close: closeNpsWidget,
  },
  landing: {
    init: initNpsLanding,
  },
};

const checkUserAnswer = async (
  userId: string,
  surveyId: string,
  npsSurveyWebservice: NpsSurveyWebservice,
  jwtCookieName: string
) => {
  await npsSurveyWebservice.getUserAnswerBySurvey(userId, surveyId, jwtCookieName);
};
