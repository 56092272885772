import { getJwt } from '../services/jwt.service';

export class AuthWebservice {
  readonly #wizbiiEnvFqdn: string;
  readonly #appId: string;
  readonly #baseUrl: string;

  constructor(wizbiiEnvFqdn: string, appId: string) {
    this.#wizbiiEnvFqdn = wizbiiEnvFqdn;
    this.#appId = appId;
    this.#baseUrl = `https://auth.${this.#wizbiiEnvFqdn}/v1`;
  }

  readonly refreshToken = (jwtCookieName: string): Promise<Response> => {
    return fetch(`${this.#baseUrl}/jwt/refresh`, {
      method: 'POST',
      body: JSON.stringify({
        appId: this.#appId,
        token: getJwt(jwtCookieName)?.token,
        refreshToken: getJwt(jwtCookieName)?.refreshToken,
      }),
      headers: {
        Authorization: `Bearer ${getJwt(jwtCookieName)?.token}`,
        'Content-Type': 'application/json',
      },
    });
  };
}
