<script lang="ts">
  import { trackEvent } from '@wizbii/utils/tracking';
  import { createEventDispatcher } from 'svelte';
  import type { Survey } from '../webservices/nps-survey.webservice';

  export let disabled = false;
  export let productName = '';
  export let survey: Survey;

  const dispatch = createEventDispatcher<{ scoreSelected: { score: number } }>();

  const selectScore = (score: number) => {
    trackEvent('nps', 'click', 'note', score.toString());
    dispatch('scoreSelected', { score });
  };
</script>

<p class="text">
  <!-- first question wording -->
  {survey.steps[0].questions[0].label.replaceAll('{productName}', productName)}
</p>
<ol class="rating">
  <!-- eslint-disable @typescript-eslint/no-unused-vars -->
  {#each Array(11) as _, i}
    <li class="rating__item">
      <button on:click={() => selectScore(i)} {disabled}>{i}</button>
    </li>
  {/each}
</ol>
<div class="hint">
  <span>{survey.steps[0].questions[0].minLabel}</span>
  <span>{survey.steps[0].questions[0].maxLabel}</span>
</div>

<style lang="scss">
  .text {
    margin: 0.5rem 0 0;
    color: var(--nps-text-color, #222333);
    text-align: center;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.38;
  }

  .rating {
    padding: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(11, minmax(0, 1fr));
    gap: 0.1875rem;
    margin: 1.875rem 0 0.625rem;
    max-width: 32.5rem;
    min-height: 1.875rem;

    &__item {
      list-style-type: none;

      button {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: var(--nps-button-radius, 5px);
        background-color: var(--nps-button-bg-color, #5c59f2);
        color: var(--nps-button-fg-color, white);
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.38;

        &:is([disabled]) {
          filter: brightness(150%);
        }

        &:not([disabled]):hover {
          box-shadow: 0 0.25rem 1rem 0 rgb(0 0 0 / 40%);
          cursor: pointer;
        }
      }
    }
  }

  .hint {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.625rem;
    font-weight: 500;
    line-height: 1.5;
    max-width: 32.5rem;
  }

  @media screen and (min-width: 46rem) {
    .text {
      margin-top: -1.875rem;
      font-size: 1.25rem;
      line-height: 1.25;
    }

    .rating {
      margin-top: 1.5625rem;
      gap: 0.5rem;
      min-height: 2.5rem;
    }
  }
</style>
